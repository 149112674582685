<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-alert
          text
          color="info"
        >
          <h3 class="text-h5">
            Qual dashboard você quer visualizar na página inicial do sistema?
          </h3>
          <v-divider
            class="my-4 info"
            style="opacity: 0.22"
          />
          <v-row
            align="center"
            no-gutters
          >
            <v-col class="grow">
              {{ descricaoDashboardSelecionado }}
            </v-col>
            <v-spacer />
            <v-col class="shrink">
              <v-btn
                color="info"
                outlined
                :disabled="selectedIndex === userDashboadSelectedIndex"
                @click="salvarDashboardUsuario"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-carousel
          v-model="selectedIndex"
          :height="height"
        >
          <v-carousel-item
            v-for="(dashboard, i) in dashboards"
            :key="i"
            :src="dashboard.src"
          />
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService';
import UsuariosService from '@/services/UsuariosService';

export default {
  name: 'DashboardUsuario',
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: () => {}
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data: () => ({
    items: [],
    selectedIndex: 0
  }),
  mounted() {
    this.getDashboards();
  },
  computed: {
    dashboards() {
      return this.items.map((item) => ({
        ...item,
        src: `/dashboards/${item.codigo}.png`
      }));
    },
    dashboardSelecionado() {
      return this.dashboards[this.selectedIndex];
    },
    descricaoDashboardSelecionado() {
      let descricao = '';
      if (this.dashboardSelecionado && this.dashboardSelecionado.titulo)
        descricao += this.dashboardSelecionado.titulo;
      if (this.dashboardSelecionado && this.dashboardSelecionado.descricao)
        descricao += ` :: ${this.dashboardSelecionado.descricao}`;
      return descricao;
    },
    userDashboadSelectedIndex() {
      return this.items.findIndex(
        (item) => this.userInfo.dashboard.id === item.id
      );
    }
  },
  methods: {
    getDashboards() {
      DashboardService.getDashboards().then(({ data }) => {
        this.items = data;
        this.selectedIndex = this.userDashboadSelectedIndex;
      });
    },
    salvarDashboardUsuario() {
      UsuariosService.salvarDashboard(this.dashboardSelecionado.id)
        .then(() => {
          this.$toast.success('Dashboard preferido salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit(
            'dashboard:novo-preferido-configurado',
            this.dashboardSelecionado
          );
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao salvar a nova configuração do dashboard.',
            '',
            { position: 'topRight' }
          );
        });
    }
  }
};
</script>
